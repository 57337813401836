import React from "react"
import styled from "styled-components"
import { colors } from "../utils"
import { Link } from "gatsby"

const StyledLink = styled(Link)`
    display: inline-block;
    min-width: 100px;
    margin: 0 10px;
    color: ${colors.primary};
    border: 1px solid ${colors.primary};
    text-decoration: none;
    font-size: 0.8rem;
    padding: 8px 20px;
    transition: all 0.3s;

    &.filled {
    background: ${colors.primary};
    color: ${colors.lightBackground};
    }


    &.marginTop {
        margin-top: 20px;
    }

    cursor: pointer;

    :hover {
    background: none;
    color: ${colors.primary};
    transition: all 0.3s;
    }

    @media only screen and (max-width: 365px) {
        width: calc(100% - 40px);
        margin: 0;
        &.mobileMarginTop {
            margin-top: 20px;
        }
    }

`

export default function LinkButton({ children, to, filled, marginTop, mobileMarginTop }) {
    return (
        <StyledLink to={to} className={`${filled ? "filled" : ""} ${marginTop ? " marginTop" : ""} ${mobileMarginTop ? " mobileMarginTop" : ""}`}>
            {children}
        </StyledLink>
    )
}