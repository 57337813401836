import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 1000px) {
    & {
        flex-wrap: wrap;
    }
  }
`


export default function AtractionsWrapper({ children }) {
    return (
        <StyledWrapper>
            {children}
        </StyledWrapper>
    )
}