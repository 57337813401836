import React from "react"
import styled from "styled-components"

import { colors, zIndex } from "../../utils"

const StyledWrapper = styled.div`
width: calc((1440px / 2) - 120px);
padding-right: 120px;

@media only screen and (max-width: 1600px) {
    & {
      width: 100%;
      padding: 0 70px;
    }
}

@media only screen and (max-width: 1000px) {
        & {
            width: 600px;
            text-align: justify;
            h2 {
                text-align: center;
            }

            margin-bottom: 30px;
        }
    }

    @media only screen and (max-width: 750px) {
        & {
            width: 100%;
            p {
                padding: 0 20px;

            }
        }
    }
`
export default function SectionText({ children }) {
    return (
        <StyledWrapper>
            {children}
        </StyledWrapper>
    )
}