import React from "react"
import styled from "styled-components"

import { colors, zIndex } from "../../utils"

const StyledWrapper = styled.div`
width: calc(25% - 16px);
margin: 0 10px;

&:first-child {
    margin-left: 0;
}
&:last-child {
    margin-right: 0;
}


& > div{
    height: calc(100vw / 3.6);
    z-index: ${zIndex.levelMinus1};
}

position: relative;
background: rgb(0,0,0);
background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.6418942577030813) 26%, rgba(0,0,0,0) 38%);

@media only screen and (max-width: 1500px) {
        & > div{
        height: calc(100vw / 3);
    }
  }

  @media only screen and (max-width: 1000px) {
    & {
        width: calc(50% - 26px);
        margin-bottom: 20px;

    &:first-child {
       margin-left: 10px;
    }
    &:last-child {
      margin-right: 10px;
    }
    }
  }

  @media only screen and (max-width: 750px) {
    & > div{
        height: calc(100vw / 1);
    }
    & {
        width: calc(100% - 40px);
        /* margin-bottom: 20px; */

    /* &:first-child {
       margin-left: 10px;
    }
    &:last-child {
      margin-right: 10px;
    } */
    }
  }
`

const StyledCaption = styled.p`
    position: absolute;
    width: 70%;
    height: 30px;
    bottom: 35px;
    left: 20px;
    z-index: ${zIndex.level2};
    margin: 0 10px;
    color: ${colors.white};
    font-size: 0.9em;
    text-align: left;
`


export default function AtractionItem({ children, caption }) {
    return (
        <StyledWrapper>
            <StyledCaption>
                {caption}
            </StyledCaption>
            {children}
        </StyledWrapper>
    )
}