import React from "react"
import styled from "styled-components"

import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const StyledSlider = styled(Slider)`
@media only screen and (max-width: 1000px) {
        display: none;
    }
`

const StyledSliderMobile = styled(Slider)`
display: none;

@media only screen and (max-width: 1000px) {
        display: inherit;
    }
`

const StyledWrapper = styled.div`
width: 50%;


@media only screen and (max-width: 1000px) {
    width: 70%;
    }

    @media only screen and (max-width: 750px) {
    width: calc(100% - 40px);
    }

`

const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1
}

const settingsMobile = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1
}


export default function SectionImageSlider({ children }) {
    return (
        <StyledWrapper>
            <StyledSlider {...settings} className="overflow-hidden">
                {children}
            </StyledSlider>

            <StyledSliderMobile {...settingsMobile} className="overflow-hidden">
                {children}
            </StyledSliderMobile>
        </StyledWrapper>
    )
}