import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"


export const StyledSectionImage = styled(GatsbyImage)`
    height: calc(100vw / 4);

    @media only screen and (max-width: 1000px) {
        & {
            height: auto;
        }
    }

    
`