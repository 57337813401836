import React from "react"
import styled from "styled-components"

import { colors, zIndex } from "../utils"

const StyledWrapper = styled.section`
width: 100%;
padding-bottom: 70px;

&.darkBackground {
    background: ${colors.lightBackground};
}
`

const StyledInnerWrapper = styled.div`
width: 1440px;
margin: auto;
text-align: center;

@media only screen and (max-width: 1500px) {
    & {
      width: calc(100% - 60px);
    }
  }

  h2 {
    padding: 70px 0 55px 0;
    margin: 0;
  }

    h2.noTopMargin {
      padding: 20px 0 55px 0;
    }



.columns {
  display: flex;
  justify-content: space-around;

  & > div {
    width: 40%;
  }
  p {
    text-align: left;
  }

  @media only screen and (max-width: 1000px) {
    & p{
      text-align: justify;
    }
  }
}

@media only screen and (max-width: 750px) {
  h2 {
    padding: 70px 0 45px 0;
  }

    width: calc(100% - 40px);
    .columns {
      flex-direction: column;
      & > div {
      width: 100%;
    }
  }

  .mobileCenter {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
  }
}

`


export default function StandardSection({ children, darkBackground }) {
  return (
    <StyledWrapper className={`${darkBackground ? "darkBackground" : ""}`}>
      <StyledInnerWrapper>
        {children}
      </StyledInnerWrapper>
    </StyledWrapper>
  )
}