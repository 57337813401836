import React from "react"
import styled from "styled-components"

import { colors, zIndex } from "../../utils"

const StyledWrapper = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
padding: 100px 0;
overflow-x: hidden;
.slick-list {margin: 0 -5px;}
.slick-slide>div {padding: 0 5px;}

@media only screen and (max-width: 1000px) {
        & {
            flex-direction: column;

        }

    }

    @media only screen and (max-width: 750px) {
        & {
            padding: 50px 0;
        }

    }
`


export default function SectionWithImageSlider({ children }) {
    return (
        <StyledWrapper>
            {children}
        </StyledWrapper>
    )
}